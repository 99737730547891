<template>
  <div class="pma-page flex-col">
    <div class="pma-box1 flex-col">
      <div class="pma-group1 flex-col">
        <div class="pma-bd1 flex-col">
          <headInfo :type="type"></headInfo>
          <span class="pma-info2">电商零售一体化</span>
          <span class="pma-paragraph1"
            >高效门店经营，升级客户体验，多端灵活配置<br />对接智能硬件，流畅产品体验</span
          >
          <!-- <div class="pma-outer3 flex-col"  @click="toNewPage(5)">
            <span class="pma-word3">立即试用</span>
          </div> -->
        </div>
        <div class="pma-bd2 flex-col">
          <div class="pma-group2 flex-col"></div>
        </div>
      </div>
      <span class="pma-word4">产品介绍</span>
      <span class="pma-txt4"
        >强大的底层架构、灵活的商业模式、全渠道营销场景、移动应用生态</span
      >
      <div class="pma-group3 flex-row">
        <div class="pma-main14 flex-col"></div>
        <img
          class="pma-pic8"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/malltwo.png"
        />
        <div class="pma-main15 flex-col"></div>
        <div class="pma-main16 flex-col"></div>
      </div>
      <div class="pma-group4 flex-row justify-between">
        <span class="pma-word5">门店一体化</span>
        <span class="pma-info3">海外跨境</span>
        <span class="pma-word6">灵活自定义</span>
        <span class="pma-info4">智慧营销</span>
      </div>
      <div class="pma-group5 flex-row justify-between">
        <span class="pma-word7">线上商城、线下门店一体化，</span>
        <span class="pma-word8"
          >跨境商品，税率结算，订单整合，物流运输，一站式解决</span
        >
        <span class="pma-txt5">营销方法、运营方式、模板修改，自定义配置</span>
        <span class="pma-word9"
          >定制品牌营销方案，精准高曝光快速提升品牌力</span
        >
      </div>
      <div class="pma-group6 flex-col">
        <div class="pma-mod1 flex-col">
          <span class="pma-txt6">产品亮点</span>
          <span class="pma-word10"
            >全渠道客户管理、奖励身份同步、会员客户分析、个性化店面装修</span
          >
          <div class="pma-main4 flex-col"></div>
          <div class="pma-main5 flex-row justify-between">
            <div class="pma-box3 flex-col">
              <div class="pma-mod2 flex-col">
                <div class="pma-outer4 flex-col">
                  <span class="pma-txt7">多端配置</span>
                  <span class="pma-paragraph2"
                    >后台管理，所有数据一目了然<br />手机APP，所有订单实时更新</span
                  >
                  <img
                    class="pma-img3"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/productright.png"
                  />
                </div>
              </div>
              <img
                class="pma-pic1"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/mallhighone.png"
              />
            </div>
            <div class="pma-box4 flex-row">
              <div
                class="pma-bd3 flex-col"
                :style="{ background: item.lanhuBg0 }"
                v-for="(item, index) in loopData0"
                :key="index"
              >
                <div
                  class="pma-main6 flex-col"
                  :style="{ background: item.lanhuBg1 }"
                >
                  <div class="pma-mod3 flex-col">
                    <span class="pma-word11" v-html="item.lanhutext0"></span>
                    <span class="pma-word12" v-html="item.lanhutext1"></span>
                    <img
                      class="pma-img4"
                      referrerpolicy="no-referrer"
                      :src="item.lanhuimage0"
                    />
                  </div>
                </div>
                <img
                  class="pma-img5"
                  referrerpolicy="no-referrer"
                  :src="item.lanhuimage1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="pma-word13">轻松高效</span>
      <span class="pma-word14"
        >云商城线上部署，多平台协同开发；新零售线下部署，智能设备一体化</span
      >
      <div class="pma-group7 flex-row justify-between">
        <div class="pma-mod4 flex-col">
          <div class="pma-box5 flex-row">
            <span class="pma-word15">聚合支付</span>
          </div>
          <div class="pma-box6 flex-row justify-between">
            <div class="pma-box29 flex-col"></div>
            <span class="pma-word16">统一订单管理</span>
          </div>
          <div class="pma-box7 flex-row justify-between">
            <div class="pma-group25 flex-col"></div>
            <span class="pma-txt8">支付渠道多场景覆盖数字化</span>
          </div>
          <div class="pma-box8 flex-row justify-between">
            <div class="pma-group26 flex-col"></div>
            <span class="pma-txt9">数字化、营销化解决方案</span>
          </div>
          <div class="pma-mod6 flex-col"></div>
        </div>
        <!-- <div class="pma-mod6 flex-col"></div> -->
        <div class="pma-mod7 flex-col">
          <div class="pma-box10 flex-row">
            <span class="pma-word17">经营场景</span>
          </div>
          <div class="pma-box11 flex-row justify-between">
            <div class="pma-box30 flex-col"></div>
            <span class="pma-word18">直播卖货，商家私域流量，边播边卖</span>
          </div>
          <div class="pma-box12 flex-row justify-between">
            <div class="pma-bd9 flex-col"></div>
            <span class="pma-word19">虚拟货品，全流程一体化服务</span>
          </div>
          <div class="pma-box13 flex-row justify-between">
            <div class="pma-mod15 flex-col"></div>
            <span class="pma-txt10">跨境商品，</span>
          </div>
          <div class="pma-mod6 flex-col"></div>
        </div>
      </div>
      <div class="pma-group9 flex-row">
        <div class="pma-box14 flex-col">
          <div class="pma-box15 flex-row">
            <span class="pma-word20">核心功能</span>
          </div>
          <div class="pma-box16 flex-row">
            <div class="pma-box17 flex-col"></div>
          </div>
          <div class="pma-box18 flex-row justify-between">
            <img
              class="pma-pic9"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallcoreone.png"
            />
            <img
              class="pma-pic10"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallcoretwo.png"
            />
            <img
              class="pma-img18"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallcorethird.png"
            />
            <img
              class="pma-pic11"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallcorefour.png"
            />
            <img
              class="pma-img19"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallcorefive.png"
            />
          </div>
          <div class="pma-box19 flex-row justify-between">
            <span class="pma-txt11">店铺装修</span>
            <span class="pma-info5">三方物流</span>
            <span class="pma-info6">补货管理</span>
            <span class="pma-word21">营销活动</span>
            <span class="pma-info7">客服售后</span>
          </div>
          <div class="pma-box20 flex-row justify-between">
            <span class="pma-word22"
              >手动波次、自动波次、截单波次等提高出库效率</span
            >
            <span class="pma-info8"
              >支持自动、手动拆合包裹，支持各种拆分发货</span
            >
            <span class="pma-word23">支持多种盘点类型，支持外部触发盘点</span>
            <span class="pma-infoBox1"
              >区分2B、2C处理流程，增加<br />连贯性，提高出库效率</span
            >
            <span class="pma-word24">针对仓库提出最优拣货、补货路径</span>
          </div>
          <div class="pma-box21 flex-row justify-between">
            <img
              class="pma-img20"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallcoresix.png"
            />
            <img
              class="pma-pic12"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallcoreseven.png"
            />
            <img
              class="pma-img21"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallcoreeight.png"
            />
            <img
              class="pma-pic13"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallcorenine.png"
            />
            <img
              class="pma-img22"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallcoreten.png"
            />
          </div>
          <div class="pma-box22 flex-row justify-between">
            <span class="pma-info9">订单管理</span>
            <span class="pma-txt12">商品管理</span>
            <span class="pma-info10">会员管理</span>
            <span class="pma-info11">聚合支付</span>
            <span class="pma-word25">积分优惠</span>
          </div>
          <div class="pma-box23 flex-row justify-between">
            <span class="pma-info12"
              >对作业中收货、存储、质检等发生的残次品进行管理</span
            >
            <span class="pma-info13"
              >精准到每一库位，输出热力图，拣货不拥挤</span
            >
            <span class="pma-info14"
              >对操作环节和业务进行绩效统计，覆盖所有业务</span
            >
            <span class="pma-txt13">可生成各类报表，助力业务人员所有场景</span>
            <span class="pma-word26"
              >动态查询仓库库容，合理分配仓库有效利用率</span
            >
          </div>
        </div>
      </div>
      <span class="pma-txt15">产品架构</span>
      <span class="pma-word31"
        >厂家直销、代理、营销策略、会员积分等全方位构建</span
      >
      <div class="pma-group10 flex-col">
        <div class="pma-box24 flex-row justify-between">
          <!-- <div class="pma-block1 flex-col">
            <div class="pma-section1 flex-col">
              <div class="pma-outer5 flex-col"></div>
              <div class="pma-outer6 flex-col"></div>
              <div class="pma-outer7 flex-col"></div>
              <div class="pma-outer8 flex-col"></div>
            </div>
            <span class="pma-info15">国内物流</span>
            <span class="pma-word32">境外物流</span>
            <span class="pma-paragraph3">MES<br />制造运营</span>
            <span class="pma-info16">ERP</span>
          </div>
          <div class="pma-block2 flex-col justify-between">
            <div class="pma-main7 flex-col"></div>
            <div class="pma-main8 flex-col">
              <div class="pma-outer9 flex-col">
                <span class="pma-info17">AI算法智能</span>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <img
          class="pma-label1"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/greendoarrow.png"
        /> -->
        <!-- <div class="pma-box25 flex-col">
          <div class="pma-mod10 flex-col">
            <div class="pma-outer10 flex-row justify-between">
              <div class="pma-box26 flex-col">
                <span class="pma-info18">活动</span>
              </div>
              <div class="pma-box27 flex-col">
                <span class="pma-txt16">会员</span>
              </div>
            </div>
            <div class="pma-outer11 flex-row justify-between">
              <div class="pma-outer12 flex-col">
                <span class="pma-info19">直销</span>
              </div>
              <div class="pma-outer13 flex-col">
                <span class="pma-info20">代理</span>
              </div>
              <div class="pma-outer14 flex-col"></div>
              <div class="pma-outer15 flex-col"></div>
            </div>
            <div class="pma-outer16 flex-row justify-between">
              <div class="pma-block3 flex-col">
                <span class="pma-txt17">MALL商城</span>
              </div>
              <div class="pma-block4 flex-col">
                <span class="pma-txt18">CRM客户管理</span>
              </div>
            </div>
            <div class="pma-outer17 flex-row">
              <div class="pma-bd4 flex-col"></div>
              <div class="pma-bd5 flex-col justify-between">
                <div class="pma-outer18 flex-col"></div>
                <div class="pma-outer19 flex-col"></div>
              </div>
              <div class="pma-bd6 flex-col"></div>
              <div class="pma-bd7 flex-col"></div>
              <div class="pma-bd8 flex-col justify-between">
                <div class="pma-layer1 flex-col"></div>
                <div class="pma-layer2 flex-col"></div>
              </div>
            </div>
            <div class="pma-outer20 flex-col">
              <span class="pma-info21">SCM供应链管理</span>
            </div>
            <div class="pma-outer21 flex-col">
              <span class="pma-word33">BI商业智能</span>
            </div>
          </div>
          <span class="pma-word34">直销</span>
          <span class="pma-word35">代理</span>
          <span class="pma-infoBox2">OMS<br />订单管理</span>
          <span class="pma-word36">境内支付</span>
          <span class="pma-word37">TMS物流管理</span>
          <span class="pma-word38">BMS计费管理</span>
          <span class="pma-word39">Pay聚合支付</span>
          <span class="pma-infoBox3">WMS<br />仓储管理</span>
          <span class="pma-word40">境外支付</span>
        </div> -->
        <!-- <img
          class="pma-label2"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/greendoarrow.png"
        />
        <img
          class="pma-label3"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/yellowdoarrow.png"
        />
        <img
          class="pma-label4"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/yellowdoarrow.png"
        /> -->
      </div>
      <span class="pma-txt19">典型案例</span>

      <div v-show="isShow" class="pma-group11 flex-row justify-between">
        <div class="pma-outer22 flex-col">
          <div class="pma-main9 flex-col">
            <img
              class="pma-pic4"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallinsone.png"
            />
          </div>
          <img
            class="pma-img14"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/mallinstwo.png"
          />
        </div>
        <div class="pma-outer23 flex-col">
          <span class="pma-word41">熊猫邮轮</span>
          <span class="pma-word42">大型央企，生产游轮，打通电商</span>
          <div class="pma-group12 flex-col"></div>
          <span class="pma-word43">痛点：</span>
          <span class="pma-infoBox4"
            >1.原系统太过于简陋，功能不完善，由于公司拓展业务，导致整体系统运营不畅<br />2.产品体验感差，用户不互动，产品传播率低，用户转化率低<br />3.涉及到海外跨境商品订单的流转，进出口关税问题等处理</span
          >
          <span class="pma-info22">解决方案：</span>
          <span class="pma-paragraph4"
            >1.订单管理系统将所有信息统一整合<br />2.高转化营销活动，拼团、砍价、秒杀、限时购、限时折扣、签到、推荐有礼等多种营销活动<br />3.设定模板，统一操作，提高效率、准确率</span
          >
          <div class="pma-group13 flex-row">
            <span class="pma-word44">70%</span>
            <span class="pma-word45">订单处理</span>
            <div class="pma-group14 flex-col"></div>
            <span class="pma-info23">50%</span>
            <span class="pma-word46">营销策略</span>
            <div class="pma-group15 flex-col"></div>
          </div>
          <div class="pma-group16 flex-row justify-between">
            <button
              class="pma-layer3 flex-col"
              @click="jumpPage('instance', 'header-ident')"
            >
              <span class="pma-info24">查看详情</span>
            </button>
            <div class="pma-icon1" @click="clickCut" />
          </div>
        </div>
      </div>
      <div v-if="!isShow" class="pmj-section20 flex-row justify-between">
        <div class="pmj-main17 flex-col">
          <div class="pmj-main18 flex-col">
            <img
              class="pmj-img11"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/mallinsthird.png"
            />
          </div>
          <img
            class="pmj-img12"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/mallinsfour.png"
          />
        </div>
        <div class="pmj-main19 flex-col">
          <span class="pmj-txt24">吉利云店商城</span>
          <span class="pmj-word38">汽车工业型公司，汽车制造类</span>
          <div class="pmj-bd6 flex-col"></div>
          <span class="pmj-word39">痛点：</span>
          <span class="pmj-paragraph5"
            >1.汽车零部件商品众多，经销商信息化水平不一，无法实现生产到销售过程全程可视化、透明化、信息化。<br />2.针对汽车服务项目众多、服务门店全国分散，不易于收集用户服务反馈和用户满意度</span
          >
          <span class="pmj-info21">解决方案：</span>
          <span class="pmj-paragraph6"
            >1.利用数据可视化以及多报表全面覆盖将所有信息整理并展示<br />2.构建统一销售商城平台，方便经销商、直营店、服务门店挂售商品和服务，方便汽车品牌下的用户在平台上根据车型等数据购买匹配的汽车官方配件及检测、保养等服务</span
          >
          <div class="pmj-bd7 flex-row">
            <span class="pmj-word56">70%</span>
            <span class="pmj-txt25">信息统筹</span>
            <div class="pmj-layer8 flex-col"></div>
            <span class="pmj-info30">40%</span>
            <span class="pmj-word40">订单处理</span>
            <div class="pmj-layer9 flex-col"></div>
          </div>
          <div class="pmj-bd8 flex-row justify-between">
            <button
              class="pmj-wrap4 flex-col"
              @click="jumpPage('instance', 'header-ident')"
            >
              <span class="pmj-info22">查看详情</span>
            </button>
            <div class="pmj-wrap5 flex-col" @click="clickCut"></div>
          </div>
        </div>
      </div>

      <secFoot />
      <footInfo />
    </div>
    <div class="pma-box28 flex-col">
      <div class="pma-main10 flex-row">
        <span class="pma-word63">用户运营</span>
      </div>
      <div class="pma-main11 flex-row justify-between">
        <div class="pma-wrap2 flex-col"></div>
        <span class="pma-txt28"
          >营销活动、裂变工具等多种方式全渠道引流获客</span
        >
      </div>
      <div class="pma-main12 flex-row justify-between">
        <div class="pma-mod16 flex-col"></div>
        <span class="pma-txt29"
          >智能标签促进精细化运营，企业风控助力高效决策</span
        >
      </div>
      <div class="pma-main13 flex-row justify-between">
        <div class="pma-bd10 flex-col"></div>
        <span class="pma-word64">自动化SOP体系精准触达客户</span>
      </div>
      <div class="pma-mod6 flex-col"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1,
      isShow: true,
      loopData0: [
        {
          // lanhuBg0:
          //   "url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngee188c1c70ee5aac50002cb91c8460433eb6d80c70629a544b568949573f5762) 100% no-repeat",
          lanhuBg1:
            "url(" +
            require("../../assets/images/back/mallgreyone.png") +
            ") 100% no-repeat",
          lanhutext0: "多平台管理",
          lanhutext1:
            "供应商后台，管理货品<br/>运营后台，营销活动，流量转化<br/>手机APP，用户体验，流畅交互",
          lanhuimage0: require("../../assets/main/productright.png"),
          lanhuimage1: require("../../assets/images/procedure/mallhightwo.png"),
        },
        {
          // lanhuBg0:
          //   "url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngee188c1c70ee5aac50002cb91c8460433eb6d80c70629a544b568949573f5762) 100% no-repeat",
          lanhuBg1:
            "url(" +
            require("../../assets/images/back/mallgreyone.png") +
            ") 100% no-repeat",
          lanhutext0: "数字化营销服务",
          lanhutext1:
            "全网一手渠道资源服务供应商，省50%推广成本<br/>定制品牌营销方案，精准高曝光快速提升品牌力",
          lanhuimage0: require("../../assets/main/productright.png"),
          lanhuimage1: require("../../assets/images/procedure/mallhighthird.png"),
        },
        {
          // lanhuBg0:
          //   "url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5c27e9463653d3506d8733abeee3835620e9e4bf557063c0de50c7ac3773d196) 100% no-repeat",
          lanhuBg1:
            "url(" +
            require("../../assets/images/back/mallgreytwo.png") +
            ") 100% no-repeat",
          lanhutext0: "私域流量转化",
          lanhutext1:
            "强流量提升，增强转化率免费触达客户，实时在线维护可持续发展",
          lanhuimage0: require("../../assets/main/productright.png"),
          lanhuimage1: require("../../assets/images/procedure/mallhighfour.png"),
        },
      ],
    };
  },
  methods: {
    jumpPage(path, id) {
      var path = path;
      var Id = id;
      localStorage.setItem("toId", Id);
      this.$router.push({
        name: path,
        params: {
          sel: "six",
        },
      });
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        case 1: // 产品介绍
          url = "/product/erp";
          break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
    clickCut() {
      this.isShow = !this.isShow;
    },
  },
};
</script>
<style  rel="stylesheet/scss" lang="scss" >
// .tab-mall{

// }
</style>
<style  rel="stylesheet/scss" lang="scss" >
.pmj-section20 {
  width: 1317px;
  height: 609px;
  margin: 49px 0 0 273px;
  .pmj-main17 {
    z-index: 485;
    height: 609px;
    overflow: hidden;
    // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdbeb744d371ed8d8f1a6e354623b73db862333f057993284c800b9d76beea742)
    //   0px 0px no-repeat;
    background-size: 590px 609px;
    width: 590px;
    position: relative;
    .pmj-main18 {
      z-index: 491;
      position: relative;
      width: 516px;
      height: 545px;
      overflow: hidden;
      // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6e222aab89903bdaa634f07c1c506ffbabc16b5e37cd74463cb2f5d44cc667c0)
      //   100% no-repeat;
      margin: 35px 0 0 56px;
      .pmj-img11 {
        z-index: 492;
        position: absolute;
        left: 0;
        top: 0;
        width: 516px;
        height: 545px;
      }
    }
    .pmj-img12 {
      z-index: 486;
      position: absolute;
      left: 0;
      top: -1px;
      width: 590px;
      height: 609px;
    }
  }
  .pmj-main19 {
    width: 645px;
    height: 545px;
    margin-top: 35px;
    .pmj-txt24 {
      width: 136px;
      height: 29px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 21px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 29px;
      text-align: left;
    }
    .pmj-word38 {
      width: 202px;
      height: 20px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 14px;
      letter-spacing: 1.75px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 20px;
      text-align: left;
      margin-top: 6px;
    }
    .pmj-bd6 {
      width: 84px;
      height: 4px;
      background-color: rgba(37, 184, 47, 1);
      margin-top: 10px;
    }
    .pmj-word39 {
      width: 54px;
      height: 30px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 16px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 30px;
      text-align: left;
      margin-top: 20px;
    }
    .pmj-paragraph5 {
      width: 645px;
      height: 90px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 14px;
      letter-spacing: 1.75px;
      font-family: AlibabaPuHuiTiR;
      line-height: 30px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .pmj-info21 {
      width: 89px;
      height: 30px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 16px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 30px;
      text-align: left;
      margin-top: 8px;
    }
    .pmj-paragraph6 {
      width: 645px;
      height: 90px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 14px;
      letter-spacing: 1.75px;
      font-family: AlibabaPuHuiTiR;
      line-height: 30px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .pmj-bd7 {
      width: 309px;
      height: 48px;
      margin: 59px 0 0 66px;
      .pmj-word56 {
        z-index: 473;
        position: absolute;
        left: 1009px;
        top: 5888px;
        width: 86px;
        height: 55px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(37, 184, 47, 1);
        font-size: 40px;
        letter-spacing: 2px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 55px;
        text-align: left;
      }
      .pmj-info30 {
        z-index: 474;
        position: absolute;
        left: 1219px;
        top: 5888px;
        width: 86px;
        height: 55px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(37, 184, 47, 1);
        font-size: 40px;
        letter-spacing: 2px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 55px;
        text-align: left;
      }
      .pmj-txt25 {
        width: 63px;
        height: 20px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        letter-spacing: 1.75px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 20px;
        text-align: left;
        margin-top: 28px;
      }
      .pmj-layer8 {
        width: 17px;
        height: 31px;
        background: url(../../assets/images/procedure/hook.png) -1px -2px
          no-repeat;
        background-size: 23px 34px;
        margin-left: 19px;
      }
      .pmj-word40 {
        width: 63px;
        height: 20px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        letter-spacing: 1.75px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 20px;
        text-align: left;
        margin: 28px 0 0 111px;
      }
      .pmj-layer9 {
        width: 17px;
        height: 31px;
        background: url(../../assets/images/procedure/hook.png) -1px -2px
          no-repeat;
        background-size: 23px 34px;
        margin: 1px 0 0 19px;
      }
    }
    .pmj-bd8 {
      width: 300px;
      height: 60px;
      margin-top: 41px;
      .pmj-wrap4 {
        height: 60px;
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
        width: 180px;
        cursor: pointer;
        .pmj-info22 {
          width: 85px;
          height: 29px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 21px;
          letter-spacing: 0.41999998688697815px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 29px;
          text-align: left;
          margin: 15px 0 0 47px;
        }
      }
      .pmj-wrap5 {
        width: 40px;
        height: 40px;
        background: url(../../assets/images/procedure/grethan.png) 100%
          no-repeat;
        // background-size: 88px 88px;
        margin-top: 10px;
      }
      .pmj-wrap5:hover {
        background: url(../../assets/images/procedure/grethangreen.png) -24px -12px
          no-repeat;
      }
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss">
.pma-page {
  position: relative;
  width: 1920px;
  height: 7639px;
  margin: 0 auto;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  .pma-box1 {
    width: 1920px;
    height: 7639px;
    .pma-group1 {
      // z-index: 3;
      height: 920px;
      overflow: hidden;
      background: url(../../assets/images/back/graythird.png) 100% no-repeat;
      width: 1920px;
      position: relative;
      .pma-bd1 {
        z-index: 80;
        width: 1920px;
        height: 662px;
        .pma-outer1 {
          height: 100px;
          width: 1920px;
          .pma-box2 {
            width: 1775px;
            height: 42px;
            margin: 29px 0 0 65px;
            .pma-img1 {
              width: 180px;
              height: 38px;
              margin-top: 2px;
            }
            .pma-txt1 {
              width: 43px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 301px;
            }
            .pma-word1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiB;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pma-info1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pma-txt2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pma-word2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pma-outer2 {
              height: 42px;
              border-radius: 21px;
              border: 2px solid rgba(37, 184, 47, 1);
              margin-left: 346px;
              width: 149px;
              .pma-txt3 {
                width: 85px;
                height: 29px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 0.94);
                font-size: 21px;
                letter-spacing: 0.41999998688697815px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 29px;
                text-align: left;
                margin: 7px 0 0 32px;
              }
            }
          }
        }
        .pma-info2 {
          width: 338px;
          height: 65px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 48px;
          letter-spacing: 0.9599999785423279px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 65px;
          text-align: left;
          margin: 221px 0 0 200px;
        }
        .pma-paragraph1 {
          width: 842px;
          height: 104px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 28px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 52px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 26px 0 0 200px;
        }
        .pma-outer3 {
          height: 68px;
          border-radius: 34px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.32);
          width: 220px;
          margin: 78px 0 0 200px;
          .pma-word3 {
            width: 113px;
            height: 38px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 28px;
            letter-spacing: 0.5600000023841858px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 38px;
            text-align: left;
            margin: 15px 0 0 54px;
          }
        }
      }
      .pma-bd2 {
        z-index: 4;
        height: 920px;
        background: url(../../assets/main/mall.png) 100% no-repeat;
        width: 1920px;
        position: absolute;
        left: 0;
        top: 0;
        .pma-group2 {
          width: 1920px;
          height: 920px;
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .pma-word4 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(39, 39, 39, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 896px;
    }
    .pma-txt4 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .pma-group3 {
      width: 1201px;
      height: 126px;
      margin: 85px 0 0 352px;
      .pma-main14 {
        width: 125px;
        height: 125px;
        background: url(../../assets/images/procedure/mallone.png) -1px -1px
          no-repeat;
        margin-top: 1px;
      }
      .pma-pic8 {
        width: 124px;
        height: 126px;
        margin-left: 235px;
      }
      .pma-main15 {
        width: 120px;
        height: 124px;
        background: url(../../assets/images/procedure/mallthird.png) 100%
          no-repeat;
        margin: 1px 0 0 238px;
      }
      .pma-main16 {
        width: 118px;
        height: 117px;
        background: url(../../assets/images/procedure/mallfour.png) -1px -1px
          no-repeat;
        margin: 5px 0 0 241px;
      }
    }
    .pma-group4 {
      width: 1175px;
      height: 29px;
      margin: 49px 0 0 361px;
      .pma-word5 {
        width: 106px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
      .pma-info3 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
      .pma-word6 {
        width: 106px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: center;
      }
      .pma-info4 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
    }
    .pma-group5 {
      width: 1325px;
      height: 56px;
      margin: 16px 0 0 291px;
      .pma-word7 {
        width: 245px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pma-word8 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pma-txt5 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pma-word9 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pma-group6 {
      height: 946px;
      background-color: rgba(37, 38, 37, 1);
      margin-top: 120px;
      width: 1920px;
      .pma-mod1 {
        width: 1920px;
        height: 810px;
        margin-top: 68px;
        .pma-txt6 {
          width: 129px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
          margin-left: 896px;
        }
        .pma-word10 {
          width: 558px;
          height: 32px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 32px;
          text-align: center;
          margin: 24px 0 0 682px;
        }
        .pma-main4 {
          width: 84px;
          height: 6px;
          background-color: rgba(37, 184, 47, 1);
          margin: 12px 0 0 919px;
        }
        .pma-main5 {
          width: 1920px;
          height: 644px;
          margin-top: 48px;
          .pma-box3 {
            z-index: 77;
            height: 644px;
            overflow: hidden;
            // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng12f6cacfdcdc4952f1d443763825efc0e051d671bbabef66164bb6cf123dbf80)
            //   100% no-repeat;
            width: 688px;
            position: relative;
            .pma-mod2 {
              z-index: 79;
              height: 644px;
              overflow: hidden;
              background: url(../../assets/images/back/mallgreythird.png) 100%
                no-repeat;
              width: 688px;
              position: relative;
              .pma-outer4 {
                width: 245px;
                height: 257px;
                margin: 248px 0 0 128px;
                .pma-txt7 {
                  width: 145px;
                  height: 50px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 36px;
                  letter-spacing: 0.7200000286102295px;
                  font-family: AlibabaPuHuiTiM;
                  white-space: nowrap;
                  line-height: 50px;
                  text-align: left;
                }
                .pma-paragraph2 {
                  width: 245px;
                  height: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 28px;
                  text-align: left;
                  margin-top: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .pma-img3 {
                  width: 55px;
                  height: 55px;
                  margin-top: 80px;
                }
              }
            }
            .pma-pic1 {
              z-index: 78;
              position: absolute;
              left: 0;
              top: 0;
              width: 688px;
              height: 644px;
            }
          }
          .pma-box4 {
            width: 1232px;
            height: 644px;
            justify-content: space-between;
            .pma-bd3 {
              z-index: 1;
              height: 644px;
              overflow: hidden;
              width: 411px;
              position: relative;
              .pma-main6 {
                z-index: 3;
                height: 644px;
                overflow: hidden;
                width: 411px;
                position: relative;
                .pma-mod3 {
                  width: 245px;
                  height: 257px;
                  margin: 248px 0 0 80px;
                  .pma-word11 {
                    width: 254px;
                    height: 50px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 36px;
                    letter-spacing: 0.7200000286102295px;
                    font-family: AlibabaPuHuiTiM;
                    white-space: nowrap;
                    line-height: 50px;
                    text-align: left;
                  }
                  .pma-word12 {
                    width: 245px;
                    height: 84px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    letter-spacing: 1.5px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 28px;
                    text-align: left;
                    margin-top: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  .pma-img4 {
                    width: 55px;
                    height: 55px;
                    margin-top: 52px;
                  }
                }
              }
              .pma-img5 {
                // z-index: 82;
                position: absolute;
                left: 0;
                top: 0;
                width: 411px;
                height: 644px;
              }
            }
          }
        }
      }
    }
    .pma-word13 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 886px;
    }
    .pma-word14 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 510px;
    }
    .pma-group7 {
      width: 1520px;
      height: 409px;
      margin: 52px 0 0 200px;
      .pma-mod4 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 488px;
        .pma-box5 {
          width: 97px;
          height: 33px;
          margin: 92px 0 0 196px;
          .pma-word15 {
            width: 97px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
          }
        }
        .pma-box6 {
          width: 133px;
          height: 28px;
          margin: 36px 0 0 144px;
          .pma-box29 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png) 0px 0px
              no-repeat;
            margin-top: 4px;
          }
          .pma-word16 {
            width: 103px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .pma-box7 {
          width: 235px;
          height: 28px;
          margin: 24px 0 0 144px;
          .pma-group25 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png) 0px 0px
              no-repeat;
            margin-top: 4px;
          }
          .pma-txt8 {
            width: 205px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .pma-box8 {
          width: 218px;
          height: 28px;
          margin: 24px 0 103px 144px;
          .pma-group26 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png) 0px 0px
              no-repeat;
            margin-top: 4px;
          }
          .pma-txt9 {
            width: 188px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
      }
      .pma-mod4:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .pma-word15,
        .pma-word16,
        .pma-txt8,
        .pma-txt9 {
          color: rgba(255, 255, 255, 1);
        }
        .pma-box29,
        .pma-group25,
        .pma-group26 {
          background: url(../../assets/images/procedure/whitetick.png) 0px 0px
            no-repeat;
        }
        .pma-mod6 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 114px;
          // margin-top: -12px;
          // margin-left: 172px;
        }
      }
      // .pma-mod6 {
      //   width: 145px;
      //   height: 25px;
      //   // background: url(../../assets/images/back/greencloth.png) -57px -70px
      //   //   no-repeat;
      //   background: #00FF11;
      //   filter: blur(28px);
      //   margin-top: 384px;
      // }
      .pma-mod7 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 487px;
        .pma-box10 {
          width: 97px;
          height: 33px;
          margin: 92px 0 0 195px;
          .pma-word17 {
            width: 97px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
          }
        }
        .pma-box11 {
          width: 304px;
          height: 28px;
          margin: 36px 0 0 92px;
          .pma-box30 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png) 0px 0px
              no-repeat;
            margin-top: 4px;
          }
          .pma-word18 {
            width: 274px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .pma-box12 {
          width: 253px;
          height: 28px;
          margin: 24px 0 0 92px;
          .pma-bd9 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png) 0px 0px
              no-repeat;
            margin-top: 4px;
          }
          .pma-word19 {
            width: 223px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .pma-box13 {
          width: 116px;
          height: 28px;
          margin: 24px 0 103px 92px;
          .pma-mod15 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png) 0px 0px
              no-repeat;
            margin-top: 4px;
          }
          .pma-txt10 {
            width: 86px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
      }
      .pma-mod7:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .pma-word17,
        .pma-word18,
        .pma-word19,
        .pma-txt10 {
          color: rgba(255, 255, 255, 1);
        }
        .pma-box30,
        .pma-bd9,
        .pma-mod15 {
          background: url(../../assets/images/procedure/whitetick.png) 0px 0px
            no-repeat;
        }
        .pma-mod6 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 114px;
        }
      }
    }
    .pma-group9 {
      width: 1919px;
      height: 838px;
      margin-top: 104px;
      .pma-box14 {
        height: 838px;
        background-color: rgba(37, 184, 47, 1);
        width: 1919px;
        .pma-box15 {
          width: 129px;
          height: 44px;
          margin: 83px 0 0 895px;
          .pma-word20 {
            width: 129px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 32px;
            letter-spacing: 0.6399999856948853px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 44px;
            text-align: left;
          }
        }
        .pma-box16 {
          width: 84px;
          height: 6px;
          margin: 24px 0 0 917px;
          .pma-box17 {
            width: 84px;
            height: 6px;
            background-color: rgba(255, 255, 255, 1);
          }
        }
        .pma-box18 {
          width: 1111px;
          height: 120px;
          margin: 76px 0 0 405px;
          .pma-pic9 {
            width: 120px;
            height: 120px;
          }
          .pma-pic10 {
            width: 120px;
            height: 120px;
          }
          .pma-img18 {
            width: 120px;
            height: 120px;
          }
          .pma-pic11 {
            width: 120px;
            height: 120px;
          }
          .pma-img19 {
            width: 120px;
            height: 120px;
          }
        }
        .pma-box19 {
          width: 1060px;
          height: 28px;
          margin: 20px 0 0 431px;
          .pma-txt11 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .pma-info5 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .pma-info6 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .pma-word21 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .pma-info7 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .pma-box20 {
          width: 1179px;
          height: 44px;
          margin: 3px 0 0 371px;
          .pma-word22 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-info8 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-word23 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-infoBox1 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-word24 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .pma-box21 {
          width: 1111px;
          height: 120px;
          margin: 56px 0 0 405px;
          .pma-img20 {
            width: 120px;
            height: 120px;
          }
          .pma-pic12 {
            width: 120px;
            height: 120px;
          }
          .pma-img21 {
            width: 120px;
            height: 120px;
          }
          .pma-pic13 {
            width: 120px;
            height: 120px;
          }
          .pma-img22 {
            width: 120px;
            height: 120px;
          }
        }
        .pma-box22 {
          width: 1060px;
          height: 28px;
          margin: 20px 0 0 431px;
          .pma-info9 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .pma-txt12 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .pma-info10 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .pma-info11 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .pma-word25 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .pma-box23 {
          width: 1180px;
          height: 44px;
          margin: 3px 0 119px 371px;
          .pma-info12 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-info13 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-info14 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-txt13 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-word26 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .pma-word27 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 665px 0 0 -1485px;
      }
      .pma-word28 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 665px 0 0 188px;
      }
      .pma-word29 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 665px 0 0 187px;
      }
      .pma-txt14 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 665px 0 0 188px;
      }
      .pma-word30 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 665px 434px 0 188px;
      }
    }
    .pma-txt15 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 123px 0 0 893px;
    }
    .pma-word31 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .pma-group10 {
      // z-index: 206;
      height: 822px;
      background-color: rgba(255, 255, 255, 1);
      margin-top: 36px;
      width: 1920px;
      position: relative;
      .pma-box24 {
        width: 754px;
        height: 703px;
        margin: 52px 0 0 582px;
        background: url(../../assets/images/procedure/mallflow.png) 100%
          no-repeat;
        .pma-block1 {
          z-index: 408;
          height: 418px;
          background-color: rgba(255, 194, 0, 0.06);
          margin-top: 174px;
          width: 106px;
          position: relative;
          .pma-section1 {
            width: 86px;
            height: 402px;
            margin: 8px 0 0 10px;
            .pma-outer5 {
              width: 86px;
              height: 85px;
              border: 1px solid rgba(37, 184, 47, 1);
            }
            .pma-outer6 {
              width: 86px;
              height: 85px;
              border: 1px solid rgba(37, 184, 47, 1);
              margin-top: 19px;
            }
            .pma-outer7 {
              width: 86px;
              height: 85px;
              border: 1px solid rgba(37, 184, 47, 1);
              margin-top: 24px;
            }
            .pma-outer8 {
              width: 86px;
              height: 85px;
              border: 1px solid rgba(37, 184, 47, 1);
              margin-top: 19px;
            }
          }
          .pma-info15 {
            z-index: 445;
            position: absolute;
            left: 31px;
            top: 25px;
            width: 44px;
            height: 52px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(18, 112, 24, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            line-height: 26px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-word32 {
            z-index: 460;
            position: absolute;
            left: 31px;
            top: 129px;
            width: 44px;
            height: 52px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(18, 112, 24, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            line-height: 26px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-paragraph3 {
            z-index: 448;
            position: absolute;
            left: 11px;
            top: 237px;
            width: 85px;
            height: 52px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(18, 112, 24, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            line-height: 26px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-info16 {
            z-index: 463;
            position: absolute;
            left: 31px;
            top: 355px;
            width: 44px;
            height: 26px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(18, 112, 24, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 26px;
            text-align: center;
          }
        }
        .pma-block2 {
          width: 638px;
          height: 631px;
          .pma-main7 {
            width: 314px;
            height: 385px;
            background-color: rgba(255, 194, 0, 0.06);
            margin-left: 324px;
          }
          .pma-main8 {
            height: 76px;
            background-color: rgba(255, 194, 0, 0.06);
            margin-top: 170px;
            width: 637px;
            .pma-outer9 {
              height: 60px;
              border: 1px solid rgba(37, 184, 47, 1);
              width: 621px;
              margin: 8px 0 0 8px;
              .pma-info17 {
                width: 87px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 267px;
              }
            }
          }
        }
      }
      .pma-label1 {
        z-index: 477;
        position: absolute;
        left: 669px;
        top: 332px;
        width: 40px;
        height: 32px;
      }
      .pma-box25 {
        z-index: 410;
        height: 619px;
        background: url(../../assets/images/back/mallgreenblock.png) 100%
          no-repeat;
        width: 638px;
        position: absolute;
        left: 698px;
        top: 52px;
        .pma-mod10 {
          width: 622px;
          height: 601px;
          margin: 10px 0 0 8px;
          .pma-outer10 {
            width: 134px;
            height: 60px;
            margin-left: 164px;
            .pma-box26 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 61px;
              .pma-info18 {
                width: 35px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 13px;
              }
            }
            .pma-box27 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 61px;
              .pma-txt16 {
                width: 35px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 13px;
              }
            }
          }
          .pma-outer11 {
            width: 622px;
            height: 60px;
            margin-top: 16px;
            .pma-outer12 {
              height: 60px;
              background-color: rgba(37, 184, 47, 1);
              width: 134px;
              .pma-info19 {
                width: 35px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 50px;
              }
            }
            .pma-outer13 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 134px;
              .pma-info20 {
                width: 35px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 50px;
              }
            }
            .pma-outer14 {
              width: 134px;
              height: 60px;
              border: 1px solid rgba(37, 184, 47, 1);
            }
            .pma-outer15 {
              width: 134px;
              height: 60px;
              border: 1px solid rgba(37, 184, 47, 1);
            }
          }
          .pma-outer16 {
            width: 621px;
            height: 60px;
            margin-top: 24px;
            .pma-block3 {
              height: 60px;
              background-color: rgba(37, 184, 47, 1);
              width: 297px;
              .pma-txt17 {
                width: 82px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 108px;
              }
            }
            .pma-block4 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              background-color: rgba(225, 255, 227, 1);
              width: 297px;
              .pma-txt18 {
                width: 106px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 96px;
              }
            }
          }
          .pma-outer17 {
            width: 621px;
            height: 189px;
            margin: 24px 0 0 1px;
            .pma-bd4 {
              width: 86px;
              height: 189px;
              border: 1px solid rgba(37, 184, 47, 1);
            }
            .pma-bd5 {
              width: 186px;
              height: 189px;
              margin-left: 24px;
              .pma-outer18 {
                width: 186px;
                height: 85px;
                border: 1px solid rgba(37, 184, 47, 1);
              }
              .pma-outer19 {
                width: 186px;
                height: 85px;
                border: 1px solid rgba(37, 184, 47, 1);
                margin-top: 19px;
              }
            }
            .pma-bd6 {
              width: 86px;
              height: 189px;
              border: 1px solid rgba(37, 184, 47, 1);
              margin-left: 27px;
            }
            .pma-bd7 {
              width: 86px;
              height: 189px;
              border: 1px solid rgba(37, 184, 47, 1);
              margin-left: 20px;
            }
            .pma-bd8 {
              width: 86px;
              height: 189px;
              margin-left: 20px;
              .pma-layer1 {
                width: 86px;
                height: 85px;
                border: 1px solid rgba(37, 184, 47, 1);
              }
              .pma-layer2 {
                width: 86px;
                height: 85px;
                border: 1px solid rgba(37, 184, 47, 1);
                margin-top: 19px;
              }
            }
          }
          .pma-outer20 {
            height: 60px;
            background-color: rgba(37, 184, 47, 1);
            margin-top: 24px;
            width: 621px;
            .pma-info21 {
              width: 123px;
              height: 32px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiM;
              line-height: 32px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 14px 0 0 249px;
            }
          }
          .pma-outer21 {
            height: 60px;
            background-color: rgba(37, 184, 47, 1);
            margin-top: 24px;
            width: 621px;
            .pma-word33 {
              width: 86px;
              height: 32px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiM;
              line-height: 32px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 14px 0 0 268px;
            }
          }
        }
        .pma-word34 {
          z-index: 427;
          position: absolute;
          left: 382px;
          top: 100px;
          width: 35px;
          height: 32px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(18, 112, 24, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          line-height: 32px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pma-word35 {
          z-index: 466;
          position: absolute;
          left: 546px;
          top: 100px;
          width: 35px;
          height: 32px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(18, 112, 24, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          line-height: 32px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pma-infoBox2 {
          z-index: 451;
          position: absolute;
          left: 170px;
          top: 271px;
          width: 85px;
          height: 52px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(18, 112, 24, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          line-height: 26px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pma-word36 {
          z-index: 442;
          position: absolute;
          left: 565px;
          top: 271px;
          width: 44px;
          height: 52px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(18, 112, 24, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          line-height: 26px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pma-word37 {
          z-index: 436;
          position: absolute;
          left: 30px;
          top: 301px;
          width: 44px;
          height: 96px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(18, 112, 24, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          line-height: 32px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pma-word38 {
          z-index: 433;
          position: absolute;
          left: 353px;
          top: 301px;
          width: 44px;
          height: 96px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(18, 112, 24, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          line-height: 32px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pma-word39 {
          z-index: 439;
          position: absolute;
          left: 459px;
          top: 301px;
          width: 44px;
          height: 96px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(18, 112, 24, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          line-height: 32px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pma-infoBox3 {
          z-index: 454;
          position: absolute;
          left: 170px;
          top: 375px;
          width: 85px;
          height: 52px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(18, 112, 24, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          line-height: 26px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pma-word40 {
          z-index: 457;
          position: absolute;
          left: 565px;
          top: 375px;
          width: 44px;
          height: 52px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(18, 112, 24, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiM;
          line-height: 26px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .pma-label2 {
        z-index: 481;
        position: absolute;
        left: 669px;
        top: 436px;
        width: 40px;
        height: 32px;
      }
      .pma-label3 {
        z-index: 485;
        position: absolute;
        left: 669px;
        top: 545px;
        width: 40px;
        height: 32px;
      }
      .pma-label4 {
        z-index: 489;
        position: absolute;
        left: 669px;
        top: 647px;
        width: 40px;
        height: 32px;
      }
    }
    .pma-txt19 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 892px;
    }
    .pma-group11 {
      width: 1318px;
      height: 609px;
      margin: 48px 0 0 273px;
      .pma-outer22 {
        // z-index: 282;
        height: 609px;
        overflow: hidden;
        // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdbeb744d371ed8d8f1a6e354623b73db862333f057993284c800b9d76beea742)
        //   0px 0px no-repeat;
        width: 590px;
        position: relative;
        .pma-main9 {
          // z-index: 285;
          position: relative;
          width: 516px;
          height: 545px;
          overflow: hidden;
          // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6e222aab89903bdaa634f07c1c506ffbabc16b5e37cd74463cb2f5d44cc667c0)
          //   100% no-repeat;
          margin: 36px 0 0 56px;
          .pma-pic4 {
            z-index: 2;
            position: absolute;
            left: 0;
            top: 0;
            width: 516px;
            height: 545px;
          }
        }
        .pma-img14 {
          z-index: 1;
          position: absolute;
          left: 0;
          top: 0;
          width: 590px;
          height: 609px;
        }
      }
      .pma-outer23 {
        width: 645px;
        height: 545px;
        margin-top: 36px;
        .pma-word41 {
          width: 91px;
          height: 29px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 21px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 29px;
          text-align: left;
        }
        .pma-word42 {
          width: 218px;
          height: 20px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 20px;
          text-align: left;
          margin-top: 6px;
        }
        .pma-group12 {
          width: 84px;
          height: 4px;
          background-color: rgba(37, 184, 47, 1);
          margin-top: 10px;
        }
        .pma-word43 {
          width: 54px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 20px;
        }
        .pma-infoBox4 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pma-info22 {
          width: 89px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 8px;
        }
        .pma-paragraph4 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pma-group13 {
          width: 311px;
          height: 73px;
          margin: 32px 0 0 64px;
          .pma-word44 {
            width: 86px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
          }
          .pma-word45 {
            width: 63px;
            height: 20px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.75px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            margin: 53px 0 0 -84px;
          }
          .pma-group14 {
            width: 17px;
            height: 31px;
            background: url(../../assets/images/procedure/hook.png) -1px -2px
              no-repeat;
            margin: 27px 0 0 19px;
          }
          .pma-info23 {
            width: 86px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
            margin-left: 109px;
          }
          .pma-word46 {
            width: 63px;
            height: 20px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.75px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            margin: 53px 0 0 -84px;
          }
          .pma-group15 {
            width: 17px;
            height: 31px;
            background: url(../../assets/images/procedure/hook.png) -1px -2px
              no-repeat;
            margin: 27px 0 0 19px;
          }
        }
        .pma-group16 {
          width: 300px;
          height: 60px;
          margin-top: 43px;
          .pma-layer3 {
            height: 60px;
            background-color: rgba(37, 184, 47, 1);
            box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
            width: 180px;
            cursor: pointer;
            .pma-info24 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiM;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 15px 0 0 47px;
            }
          }
          .pma-icon1 {
            width: 40px;
            height: 40px;
            margin-top: 10px;
            background: url(../../assets/images/procedure/grethan.png) 100%
              no-repeat;
          }
          .pma-icon1:hover {
            background: url(../../assets/images/procedure/grethangreen.png) -24px -12px
              no-repeat;
          }
        }
      }
    }
    .pma-txt20 {
      width: 181px;
      height: 50px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 36px;
      letter-spacing: 0.7200000286102295px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 50px;
      text-align: left;
      margin: 92px 0 0 869px;
    }
    .pma-group17 {
      width: 1236px;
      height: 264px;
      margin: 32px 0 0 342px;
      .pma-pic5 {
        width: 340px;
        height: 264px;
      }
      .pma-icon2 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 28px;
      }
      .pma-pic6 {
        width: 340px;
        height: 264px;
        margin-left: 34px;
      }
      .pma-label5 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 34px;
      }
      .pma-img15 {
        width: 340px;
        height: 264px;
        margin-left: 28px;
      }
    }
    .pma-group18 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .pma-word47 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pma-txt21 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pma-word48 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .pma-group19 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .pma-infoBox5 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pma-infoBox6 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pma-infoBox7 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pma-group20 {
      width: 1236px;
      height: 264px;
      margin: 40px 0 0 342px;
      .pma-img16 {
        width: 340px;
        height: 264px;
      }
      .pma-icon3 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 28px;
      }
      .pma-img17 {
        width: 340px;
        height: 264px;
        margin-left: 34px;
      }
      .pma-label6 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 34px;
      }
      .pma-pic7 {
        width: 340px;
        height: 264px;
        margin-left: 28px;
      }
    }
    .pma-group21 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .pma-word49 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pma-txt22 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pma-word50 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .pma-group22 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .pma-paragraph5 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pma-infoBox8 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pma-paragraph6 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pma-group23 {
      height: 527px;
      background-color: rgba(38, 38, 38, 1);
      margin-top: 120px;
      width: 1920px;
      .pma-section2 {
        width: 1520px;
        height: 362px;
        margin: 81px 0 0 201px;
        .pma-mod11 {
          width: 1015px;
          height: 36px;
          margin-left: 128px;
          .pma-word51 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-word52 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 156px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-txt23 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-word53 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pma-info25 {
            width: 87px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 1px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 36px;
            text-align: left;
            margin-left: 388px;
          }
        }
        .pma-mod12 {
          width: 1138px;
          height: 56px;
          margin: 20px 0 0 128px;
          .pma-layer4 {
            width: 73px;
            height: 56px;
            .pma-info26 {
              width: 73px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pma-txt24 {
              width: 64px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pma-layer5 {
            width: 67px;
            height: 56px;
            margin-left: 121px;
            .pma-txt25 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pma-word54 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pma-word55 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .pma-txt26 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .pma-word56 {
            width: 210px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 16px 0 0 359px;
          }
        }
        .pma-mod13 {
          width: 1262px;
          height: 88px;
          margin: 8px 0 0 128px;
          .pma-section3 {
            width: 77px;
            height: 88px;
            .pma-word57 {
              width: 68px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pma-word58 {
              width: 77px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .pma-word59 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pma-section4 {
            width: 67px;
            height: 56px;
            margin-left: 117px;
            .pma-word60 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pma-info27 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pma-section5 {
            width: 334px;
            height: 84px;
            margin-left: 667px;
            .pma-info28 {
              width: 334px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .pma-word61 {
              width: 220px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .pma-word62 {
          width: 68px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 0.761904776096344px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 8px 0 0 128px;
        }
        .pma-txt27 {
          width: 632px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 81px 0 0 443px;
        }
        .pma-mod14 {
          width: 1520px;
          height: 1px;
          margin-top: 16px;
        }
      }
    }
  }
  .pma-box28 {
    // z-index: 100;
    height: 396px;
    border: 1px solid rgba(245, 245, 245, 1);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
    width: 488px;
    position: absolute;
    left: 716px;
    top: 2839px;
    .pma-main10 {
      width: 97px;
      height: 33px;
      margin: 92px 0 0 196px;
      .pma-word63 {
        width: 97px;
        height: 33px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 24px;
        letter-spacing: 0.47999998927116394px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 33px;
        text-align: left;
      }
    }
    .pma-main11 {
      width: 389px;
      height: 28px;
      margin: 36px 0 0 41px;
      .pma-wrap2 {
        width: 21px;
        height: 21px;
        background: url(../../assets/images/procedure/tick.png) 0px 0px
          no-repeat;
        margin-top: 4px;
      }
      .pma-txt28 {
        width: 359px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 28px;
        text-align: center;
      }
    }
    .pma-main12 {
      width: 406px;
      height: 28px;
      margin: 24px 0 0 41px;
      .pma-mod16 {
        width: 21px;
        height: 21px;
        background: url(../../assets/images/procedure/tick.png) 0px 0px
          no-repeat;
        margin-top: 4px;
      }
      .pma-txt29 {
        width: 376px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 28px;
        text-align: center;
      }
    }
    .pma-main13 {
      width: 253px;
      height: 28px;
      margin: 24px 0 103px 41px;
      .pma-bd10 {
        width: 21px;
        height: 22px;
        background: url(../../assets/images/procedure/tick.png) 0px 0px
          no-repeat;
        margin-top: 4px;
      }
      .pma-word64 {
        width: 223px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 28px;
        text-align: center;
      }
    }
  }
  .pma-box28:hover {
    background-color: rgba(37, 184, 47, 1);
    box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
    .pma-word63,
    .pma-txt28,
    .pma-word64,
    .pma-txt29 {
      color: rgba(255, 255, 255, 1);
    }
    .pma-wrap2,
    .pma-mod16,
    .pma-bd10 {
      background: url(../../assets/images/procedure/whitetick.png) 0px 0px
        no-repeat;
    }
    .pma-mod6 {
      width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 114px;
      // margin-top: -12px;
      // margin-left: 172px;
    }
  }
}
</style>
<style src="../../assets/css/common.css" />